// useAnimatedElement.js
import { useEffect, useState } from 'react';

const useAnimatedElement = (elementId) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const element = document.getElementById(elementId);
      if (!element) {
        return;
      }
      const elementHeight = element.clientHeight;
      
      const rect = element.getBoundingClientRect();
      const isInView =
        rect.top <= window.innerHeight && rect.top > 0;

      if (isInView && !isVisible) {
        setIsVisible(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [elementId, isVisible]);

  return isVisible;
};

export default useAnimatedElement;
