import React, { useState, useEffect } from 'react';
import { Box, Avatar, Text } from '@chakra-ui/react';
import Clock from './GetCurrentDateTime';
import { IoCheckmarkOutline, IoCheckmarkDoneOutline, IoCheckmarkDoneSharp } from "react-icons/io5";

function MessageCard({ message, sender }) {
  const [showTypingAnimation, setShowTypingAnimation] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const [tickType, setTickType] = useState('single');

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowTypingAnimation(false);
      setShowMessage(true);
    }, 2000); // Wait for 2 seconds before showing the message

    const tickTimer = setTimeout(() => {
      setTickType('double');
    }, 4000); // Change tick to double after 1 second

    const colorTimer = setTimeout(() => {
      setTickType('colorChange');
    }, 6000); // Change color after 1 second

    return () => {
      clearTimeout(timer);
      clearTimeout(tickTimer);
      clearTimeout(colorTimer);
    };
  }, []);

  return (
    <>
      {showTypingAnimation && (
        <Box
          display="flex"
          alignItems="flex-start" // Align items to the left side
          opacity={1}
          color="white" // Set text color to white
          p="3"
        >
          <Avatar size="sm" name={sender.name} src={sender.avatar} className='hover-effect'/>
          <Box ml="3" bg="#3c0d6f4f" p="3" borderRadius="lg" className='hover-effect'>
            <Text fontSize="sm" fontWeight="bold" color="purple.200" >{sender.name}</Text>
            <Text fontSize="md" color="gray.100" className="typing-animation">.</Text> {/* Apply the class here */}
          </Box>
        </Box>
      )}
      {showMessage && (
        <>
        <Box
          display="flex"
          alignItems="flex-start" // Align items to the left side
          animation="fadeIn 0.5s ease-out"
          opacity={1}
          color="white" // Set text color to white
          p="3"
          
        >
          <Avatar size="sm" name={sender.name} src={sender.avatar} className='hover-effect' />
          <Box ml="3" bg="#3c0d6f4f" p="3" borderRadius="lg" className='hover-effect'>
            <Text fontSize="sm" fontWeight="bold" color="purple.200">{sender.name}</Text>
            <Text fontSize="md" color="gray.100"  >{message}</Text>
          </Box>
        </Box>

        <Box textAlign="end">
        {tickType === 'single' && <Text fontSize="sm" fontWeight="bold" color="gray.300" float="right"><IoCheckmarkOutline fontSize="large"/></Text>}
        {tickType === 'double' && <Text fontSize="sm" fontWeight="bold" color="gray.300" float="right"><IoCheckmarkDoneSharp fontSize="large"/></Text>}
        {tickType === 'colorChange' && <Text fontSize="sm" fontWeight="bold" color="purple.200" float="right"><IoCheckmarkDoneOutline fontSize="large"/></Text>}
        <Text pr="2" fontSize="sm" fontWeight="bold" color="gray.300" float="right"><Clock type="loadTime"/></Text>
        </Box>
        </>
      )}
    </>
  );
}

export default MessageCard;
