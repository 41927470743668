import React from 'react';
import { Card, CardHeader, Heading, Box, Text, Image, Flex, Center, Divider  } from '@chakra-ui/react';
import myProfilePic from './assets/images/myProfile.jpg'; 
import { CiLocationOn  } from "react-icons/ci";
import Welcome from './aboutme/Welcome';
import Journey from './aboutme/Journery';
import Drivesme from './aboutme/DrivesMe';
import Collabration from './aboutme/Collabration';
import BeyondCoding from './aboutme/BeyondCoding';

const Aboutme = () => {

    return (
        <>
    <Center >
    <Card marginTop={{ base:"10vh", md:"15vh"}} bg="#ffffff00" color="white" p="2" width="100%">
        <Flex direction={{ base: "column", md: "row" }}>
            <Box
                flex={{ base: "none", md: "0.30" }}
                textAlign={{ base: "center", md: "center" }}
                mb={{ base: "4", md: "0" }}
                align="center"    // Center vertically
                justify="center"  // Center horizontally
            >
                <Center mt="12%">
                <Image
                    src={myProfilePic}
                    alt="Jeet Kapdi"
                    className='hover-effect profile-image'
                />
                </Center>
            </Box>

            {/* Content on the left side */}
            <Box flex="1" textAlign="left">
                <CardHeader pt="2" pb="2">
                    <Heading className='name lilita-one-regular'>JEET KAPDI</Heading>
                    <Heading fontSize={{ base:"2rem", md:"3rem"}} color="purple.100" className='caveat-fonts'>Sr. Software Engineer</Heading>
                    <Flex mt="2">
                    <Text pt="2" fontSize="xl">Steeped in the craft of coding, harnessing 4.6 years of experiential mastery.</Text>
                    <Center height='20px' mt="3" ml="3" mr="3">
                    <Divider orientation='vertical' />
                    </Center>
                    <Text pt="2" fontSize="xl">
                        <Flex><CiLocationOn style={{ "margin-top":"4px","margin-right":"5px"}} />Ahmedabad, Gujarat</Flex>
                    </Text>

                    </Flex>
                </CardHeader>
            </Box>
        </Flex>
    </Card>
    </Center>

    <div style={{ "min-height":"570px" }}>
    <Welcome />
    </div>

    <Journey mt={10}/>
    <Drivesme mt={10}/>
    <Collabration mt={10}/>    
    <BeyondCoding mt={10}/>    
    
    </>
    );

}

export default Aboutme;
