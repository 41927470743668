// Portfolio.js

import React , { Suspense, useState, useEffect, useRef  } from 'react';
import { Heading, Center, Grid, Text, Button } from '@chakra-ui/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import PROJECTS from './data/projects.js';
import './assets/css/portfolio.css';
import { EffectCoverflow, Pagination, Autoplay } from 'swiper/modules';
import { useNavigate } from 'react-router-dom';

const Portfolio = () => {
  const navigate = useNavigate();
  const handleURL = (hash) => {
   // Check if there's a hash value
   if (hash) {
    // Remove the '#' symbol from the hash
    const targetId = hash.substring(1);
    // Find the target element by ID
    const targetElement = document.getElementById(targetId);
    // Check if the target element exists
    if (targetElement) {
    // Scroll to the target element without animation
    targetElement.scrollIntoView({ behavior: 'auto', block: 'start' });
    }
}
  }
  
  useEffect(() => {
    // Get the hash value from the URL
    const hash = window.location.hash;
    handleURL(hash);
}, []);

    return (
        <Center>
            <Grid mt='10%' textAlign="center" >
            <Center className=''>
                <Heading  fontSize={{ base:"2rem", md:"3rem"}} color="purple.100" mb="2%" className='caveat-fonts heading '>Portfolio</Heading>
            </Center>
            <Center >
                <Swiper
                    effect={'coverflow'}
                    grabCursor={true}
                    centeredSlides={true}
                    slidesPerView={'auto'}
                    coverflowEffect={{
                    rotate: 50,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: true,
                    }}
                    pagination={false}
                    modules={[EffectCoverflow, Pagination, Autoplay]}
                    className="PortfolioSlider"
                    loop={true}
                    autoplay={{ // Enable autoplay configuration
                    delay: 8000, // Delay between slides in milliseconds (default: 3000)
                    disableOnInteraction: false, // Pause autoplay on interaction (default: true)
                    disableOnLoop: false, // Pause autoplay on loop (default: true)
                    }}
                >
                    {PROJECTS.map((PROJECT, index) => (
                        <SwiperSlide key={PROJECT.id}>
                            <div className="swiper-zoom-container">
                                <a  onClick={() => navigate(`/portfolio-detail`, { state: { projectId: PROJECT.id } })} >
                                <img src={PROJECT.img} alt={`Project ${index + 1}`} className='hover-effect' />
                                </a>
                                <Text color="white" mt={4} className='anta-fonts'>{PROJECT.title} </Text>
                                <Text mt={4} color="purple.500" fontSize={18}>
                                    <a href={PROJECT.link} target="_blank" rel={PROJECT.description}>{PROJECT.link}</a>
                                </Text>
                                {/* Wrap the <Link> within a <Router> context */}
                                <Button
                                  mt={5}  
                                  size='md'
                                  height='48px'
                                  width='200px'
                                  border='2px'
                                  borderColor='purple.500'
                                  bg="#3d3d3d33"
                                  color="white"
                                  onClick={() => navigate(`/portfolio-detail`, { state: { projectId: PROJECT.id } })}
                                  className='portfolioButton'
                                >
                                 Know More
                                </Button>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                </Center>
            </Grid>
        </Center>
    );
};

export default Portfolio;
