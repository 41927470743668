import './assets/css/connectivity.css';
import { FaLinkedin } from "react-icons/fa";
import { SiGmail } from "react-icons/si";
import { BsFillTelephoneFill } from "react-icons/bs";
import { FaInstagram } from "react-icons/fa6";
import { IoLogoWhatsapp } from "react-icons/io";
import { FaGithub } from "react-icons/fa";
import { Card, CardBody, Button, Stack, Image, Center, Grid, SimpleGrid, Heading, FormControl, Text } from '@chakra-ui/react';
const Connectivity = () => {

    return (
      <>
      {/* <Grid  textAlign="center" w="100%">
      <SimpleGrid columns={{base : 1, md : 2}} justifyContent={'space-between'} display={'flex'}> */}
        <div className='footer'>
        <header>
          <h2>Jeet Kapdi</h2>
          <p>"Let's explore the digital ether together"</p>
        </header>
        <div className='social-links'>
          <ul class='category'>
            <li>
              <a href="https://www.linkedin.com/in/jeetkapdi/" target="_blank"><FaLinkedin /></a>
            </li>
            
          </ul>
          <ul class='category'>
            <li>
            <a href="mailto:info@jeetkapdi.com" target="_blank"><SiGmail/></a>
            </li>
          </ul>
          <ul class='category'>
            <li>
            <a href="tel:9998132125" target="_blank"><BsFillTelephoneFill /></a>
            </li>
          </ul>
          <ul class='category'>
            <li>
              <a href="https://www.instagram.com/jeet_kapdi19/" target="_blank"><FaInstagram /></a>
            </li>
          </ul>
          <ul class='category'>
            <li>
             <a href=" https://wa.me/919998132125" target="_blank"><IoLogoWhatsapp /></a>
            </li>
          </ul>
          <ul class='category'>
            <li>
              <a href="https://github.com/spark13code" target="_blank"><FaGithub /></a>
            </li>
          </ul>
        </div>
      </div>
      {/* </SimpleGrid>
      </Grid> */}
      </>
    );

}
export default Connectivity;