import React, { useState, useEffect, useRef } from 'react';
import { Text, Box, Flex, Image, Heading, SimpleGrid, Grid } from "@chakra-ui/react";
import JourneryAnimation from '../assets/images/animations/journey.png';
import useAnimatedElement from '../assets/js/useAnimatedElement';

const Journey = () => {

  let isVisibleContent = useAnimatedElement('myJourney');
  let isVisibleImage = useAnimatedElement('myJourneyImage');

    return (
      <Grid>
      <Flex mt={40} justifyContent="space-between" alignItems="center">
         <SimpleGrid columns={{ base: 1, md:2 }} alignItems="center" justifyContent="center" >
       <Box color="white"  className={`slide-left-animation ${isVisibleContent ? 'visible-left' : ''}`} id="myJourney" textAlign={{base:"center",md:"left"}}>
        <Heading fontSize={{ base:"2rem", md:"3rem"}} color="purple.100" className='caveat-fonts'>My Journey:</Heading>
        <Text mt={4} className='paragraph'>
        My journey into the world of software development began in 2019, where I embarked on a path fueled by curiosity and a thirst for knowledge. Since then, I've immersed myself in various programming languages and frameworks, mastering tools such as HTML, CSS, JavaScript, jQuery, PHP, Laravel, CodeIgniter, and ReactJS. Self-learning has been my key approach, and through relentless hard work, I've acquired a deep understanding of programming. I dedicate time every day to practice, exploring new methods, designs, and coding languages to continually enhance my skills. Over the past 4.6 years, I've witnessed substantial progress in my abilities, evolving into a dedicated senior Software Engineer.
        </Text>
       </Box>
       <Box className={`slide-right-animation  ${isVisibleImage ? 'visible-right' : ''}`} id="myJourneyImage" mt={{ base:"10%"}}>
        <Image src={JourneryAnimation} w={{base:"100%",md:"60%"}} float="right" className={`${isVisibleImage ? 'image-floating' : ''}`}/>
       </Box>
       </SimpleGrid>
       </Flex>
       </Grid>
    );

}

export default Journey;
