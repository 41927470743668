// import `ChakraProvider` component
import { ChakraProvider, extendTheme  } from '@chakra-ui/react';

// Define your custom theme
const theme = extendTheme({
    colors: {
      black: '#000000', // Black color
      gray: {
        50: '#f7fafc', // Lightest gray
        100: '#edf2f7',
        200: '#e2e8f0',
        300: '#cbd5e0',
        400: '#a0aec0',
        500: '#718096', // Middle gray
        600: '#4a5568',
        700: '#2d3748',
        800: '#1a202c',
        900: '#171923' // Darkest gray
      }
    }
  });

  export default theme;