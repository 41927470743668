// ViewAllBlogs.js
import React, { useState, useEffect } from 'react';
import { Heading, Center, Grid, SimpleGrid, Card, CardHeader, Text, Flex, CardBody, Box, Stack, Image, CardFooter, Button } from '@chakra-ui/react';
import BLOGS from './data/blogs';
import { useNavigate } from 'react-router-dom';
import '../src/assets/css/blogs.css';
import theme from './custom-components/Theme';

import { TiArrowBack  } from "react-icons/ti";

// import `ChakraProvider` component
import { ChakraProvider, extendTheme  } from '@chakra-ui/react';

const ViewAllBlogs = () => {
    const navigate = useNavigate();

    const handlePress = () => {
        navigate('/#blogs', { state: { userId: 789 } });
    }

    useEffect(() => {
        const top = document.getElementById('topviewAllBlogs');
        top.scrollIntoView({ behavior: 'auto', block: 'start' });
      },[]);

    return (
        <div className='container' id="topviewAllBlogs">
        <ChakraProvider theme={theme}>
          <Center>
        <Grid mt={10} alignItems="center">
          
           <Box  >
            <Flex >
              <Button 
               mt={5}  
               size='md'
               color="purple.200"
               className='back-button'
               onClick={handlePress}
               >
                <TiArrowBack size="xl"/>
               </Button>
            <Center>
             <Heading fontSize={{ base:"2rem", md:"4rem"}} color="purple.100" className='caveat-fonts'>Blogs</Heading>
            </Center>
            </Flex>
            </Box>

            <SimpleGrid columns={{ base: 1, md: 2 }} spacing="10" width="100%" alignItems="center" justifyContent="center" mt={8}>
                {BLOGS.map((BLOG, index) => (
                 <Card data-type='Card'
                 direction={{base:'column',sm:'row'}}
                 overflow='hidden'
                 key={BLOG.id}
                 bg="#3d3d3d3d"
                 className="blogs hover-effect"
                 id="mainContainer"
               >
                 <Image data-type='Image'
                   objectFit='cover'
                   maxW={{base:'100%',sm:'200px'}}
                   src={BLOG.img}
                   alt='Caffe Latte'
                 />
               
                 <Stack data-type='Stack'>
                   <CardBody data-type='CardBody'
                   >
                     <Heading data-type='Heading' size='md' id="header">{BLOG.title}</Heading>
               
                     <Text data-type='Text' py='2' dangerouslySetInnerHTML={{
                                                 __html: BLOG.description.substring(0, 80) + ((BLOG.title + BLOG.description).length > 80
                                                     ? '...'
                                                     : '')
                                             }}
                                             className="blog-description"></Text>
                   </CardBody>
               
                   <CardFooter data-type='CardFooter'>
                   <Button
                         size='md'
                         height='48px'
                         width='200px'
                         border='2px'
                         borderColor='purple.500'
                         bg="#3d3d3d33"
                         color="white"
                         onClick={() => navigate(`/blog-detail`, { state: { blogId: BLOG.id } })}
                         className='blogsButton'
                     >
                         Read More
                     </Button>
                   </CardFooter>
                 </Stack>
               </Card>
                ))}
                </SimpleGrid>
    
        </Grid>    
        </Center>
        </ChakraProvider>
        </div>
    );
};

export default ViewAllBlogs;