import React , { Suspense, useState, useEffect, useRef  } from 'react';
import MyNameAnimation from './MyNameAnimation';
import LandingPage from './Landing';
import './RoutesProvider.js';
import { BrowserRouter as Router, Routes, Route, useParams } from 'react-router-dom';
import theme from './custom-components/Theme';
import Footer from './Footer.js';
// Css
import './assets/css/index.css';
import './assets/css/footer.css';

// JS
import './assets/js/backgroundanimation.js';

// import `ChakraProvider` component
import { ChakraProvider, extendTheme  } from '@chakra-ui/react';

// import Portfolio Detail Page
import PortfolioDetail from './PortfolioDetail';

// import Blog Detail Page
import BlogDetail from './BlogDetail.js';

// import View All Blogs Page
import ViewAllBlogs from './ViewAllBlogs.js';


  
  // Your React component
  function App() {
    // Get the hash value from the URL
    const hash = window.location.hash;

    const animationFlag = (hash) ? true : false;

    const [showNewLayout, setShowNewLayout] = useState(animationFlag);


    const handleAnimationComplete = () => {
      setShowNewLayout(true);
    };

    const handleURL = (hash) => {
     // Check if there's a hash value
     if (hash) {
      // Remove the '#' symbol from the hash
      const targetId = hash.substring(1);
      // Find the target element by ID
      const targetElement = document.getElementById(targetId);
      // Check if the target element exists
      if (targetElement) {
      // Scroll to the target element without animation
      targetElement.scrollIntoView({ behavior: 'auto', block: 'start' });
      }
  }
    }
    
    useEffect(() => {
      // Get the hash value from the URL
      const hash = window.location.pathname;
      handleURL(hash);
    }, []);

 let mainPage = (
      <ChakraProvider theme={theme}>
      {showNewLayout ? (
        <>
        <LandingPage />
        <Footer/>
        </>
      ) : (
        <MyNameAnimation onAnimationComplete={handleAnimationComplete} />
      )}
    </ChakraProvider>
    );

   return (
    <Router>
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="/" element={mainPage} id="main" />
        <Route path="/portfolio-detail" element={<PortfolioDetail />} />
        <Route path="/blog-detail" element={<BlogDetail />} />
        <Route path="/view-all-blogs" element={<ViewAllBlogs />} />
      </Routes>
    </Suspense>
    </Router>
  );

  }
  
  export default App;