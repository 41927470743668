// PortfolioDetail.js
import React, { useState, useEffect } from 'react';
import { Heading, Center, Grid, Text, Box, Flex, Button } from '@chakra-ui/react';
import BLOGS from './data/blogs';
import { useLocation } from 'react-router-dom';

import { CardBody, Card, Image, Stack } from '@chakra-ui/react';

// import `ChakraProvider` component
import { ChakraProvider, extendTheme  } from '@chakra-ui/react';

import { TiArrowBack  } from "react-icons/ti";

import { useNavigate } from 'react-router-dom';

import theme from './custom-components/Theme';

const BlogDetail = () => {

  const navigate = useNavigate();
 
  const { state } = useLocation();

  const blogId = state?.blogId;
  
  const condition = (obj) => obj.id == blogId;
  const Blog = BLOGS.find(condition);

  const handlePress = () => {
    navigate('/#blogs', { state: { userId: 789 } });
  }

  useEffect(() => {
    const top = document.getElementById('topBlogDetail');
    top.scrollIntoView({ behavior: 'auto', block: 'start' });
  },[]);
  
  if (!Blog) {
    // Handle the case where the blog with the given id is not found
    return (
      <ChakraProvider theme={theme} id="topBlogDetail">
        <Center>
        <Flex alignItems="center">
        <Box color="white" >
          <Heading fontSize={{ base:"2rem", md:"3rem"}} color="purple.100" className='caveat-fonts'>Blog Not Found!</Heading>
        </Box>
        </Flex>
        </Center>
      </ChakraProvider>
    );
  }
 
  return (
    <div className='container' id="topBlogDetail">
    <ChakraProvider theme={theme}>
      <Center>
    <Grid mt={10} alignItems="center">
      
       <Box  >
        <Flex >
          <Button 
           mt={5}  
           size='md'
           color="purple.200"
           onClick={handlePress}
           className='back-button'
           >
            <TiArrowBack size="xl"/>
           </Button>
        <Center>
        <Heading fontSize={{ base:"2rem", md:"4rem"}} color="purple.100" className='caveat-fonts'>{Blog.title}</Heading>
        </Center>
        </Flex>
        </Box>

        <Card
            overflow='hidden'
            bg="#3d3d3d33"
            mt={8}
        >
             <Stack 
                position="relative" 
                top="0" 
                left="0" 
                zIndex="1" // Set z-index to ensure text stays above image
                className='blog-detail-img-stack'
              >  
            <Image
                src={Blog.img}
                alt={Blog.description}
                className='blog-detail-img'
            />
               </Stack>
                <CardBody zIndex="2" >
                    <Heading size='md' className='blog-title' color="purple.300">{Blog.title}</Heading>

                    <Text py='2' dangerouslySetInnerHTML={{__html: Blog.description}} className="blog-description"></Text>
                </CardBody>
        
        </Card>

       </Grid>
       
       </Center>
    </ChakraProvider>
    
    </div>
  );
};

export default BlogDetail;
