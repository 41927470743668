import React, { useState, useEffect, useRef } from 'react';
import { Text, Box, Flex, Image, Heading, SimpleGrid, Grid } from "@chakra-ui/react";
import BeyondCodingAnimation from '../assets/images/animations/beyondCoding.png';
import useAnimatedElement from '../assets/js/useAnimatedElement';

const BeyondCoding = () => {

    let isVisibleContent = useAnimatedElement('beyondCoding');
    let isVisibleImage = useAnimatedElement('beyondCodingImage');

    return (
      <Grid>
      <Flex mt={40} justifyContent="space-between" alignItems="center">
      <SimpleGrid columns={{ base: 1, md:2 }} alignItems="center" justifyContent="center" >
        <Box id="beyondCodingImage"  className={`slide-left-animation ${isVisibleImage ? 'visible-left' : ''}`}>
        <Image src={BeyondCodingAnimation} w={{ base:"100%", md:"60%"}} float="left" className={`${isVisibleImage ? 'image-floating' : ''}`}/>
       </Box>

       <Box color="white" float="right" id="beyondCoding" className={`slide-right-animation  ${isVisibleContent ? 'visible-right' : ''}`} textAlign={{base:"center",md:"right"}}>
        <Heading fontSize={{ base:"2rem", md:"3rem"}} color="purple.100" className='caveat-fonts'>Beyond Coding:</Heading>
        <Text mt={4} className='paragraph'>
        Beyond coding, I'm a lifelong learner and avid reader, always seeking opportunities for growth. I enjoy playing table tennis for its blend of competition and physical activity. Additionally, I prioritize regular exercise, recognizing its benefits for both my physical health and mental stability. I also find inspiration in watching anime, as it teaches valuable lessons about personal growth, self-confidence, and the importance of working diligently to pursue one's goals. This diverse range of interests reflects my commitment to a well-rounded and resilient journey of personal development.
        </Text>
       </Box>

       </SimpleGrid>
       </Flex>
       </Grid>
    );
}

export default BeyondCoding;
