import React, { useState, useEffect } from 'react';
import { Flex, Box, Button, Avatar, AvatarBadge } from '@chakra-ui/react';
import myProfilePic from './assets/images/logo/jk-logo-grey.png'; 


const Navbar = (activePanel) => {
  const [showNavbar, setShowNavbar] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = 0;
      if (window.scrollY > 100) {
        setShowNavbar(true);
      } else {
        setShowNavbar(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      {showNavbar && (
        <Flex
          as="nav"
          align="center"
          justify="space-between"
          wrap="wrap"
          padding="0.8rem"
          bgGradient="linear(to-r,black,purple.700)"
          color="white"
          position="fixed"
          top="0"
          left="0"
          right="0"
          zIndex="999"
          display={{ sm:"none",md:"flex"}}
        >
          <Flex align="center" ms={3}   >
          <Avatar size="sm" name="Jeet Kapdi" src={myProfilePic} className='hover-effect' mr={4}>
          {/* <AvatarBadge boxSize='0.8rem' bg='green.500' border="2px solid" borderColor="white"/> */}
          </Avatar>
            <Box as="h1" fontSize="xl" fontWeight="bold" className='profile-name'>
              Jeet Kapdi
            </Box>
          </Flex>

          <Box width={{ base: 'full', md: 'auto' }} alignItems="center" flexGrow={1} display={{ base:"none",md:"block"}}>
            <Box as="nav" alignItems="center" flexGrow={1} align="right">

              <Button variant="ghost" mr={2} color="white"
               _hover={{ color: 'purple.200' }}
               _active={{ color: 'purple.200'}}
                isActive={(activePanel.activePanel == 'AboutMe') ?? true}
              >
                <a href="#aboutme" className='anta-fonts'>About Me</a>
              </Button>

              <Button variant="ghost" mr={2} color="white"
               _hover={{ color: 'purple.200'}}
               _active={{ color: 'purple.200'}}
                isActive={(activePanel.activePanel == 'Portfolio') ?? true}
              >
                <a href="#portfolio" className='anta-fonts'>Portfolio</a>
              </Button>

              <Button variant="ghost" mr={2} color="white"
               _hover={{ color: 'purple.200'}}
               _active={{ color: 'purple.200'}}
                isActive={(activePanel.activePanel == 'Blogs') ?? true}
              >
                <a href="#blogs" className='anta-fonts'>Blogs</a>
              </Button>

              <Button variant="ghost" mr={2} color="white"
                _hover={{ color: 'purple.200'}}
                _active={{ color: 'purple.200'}}
                isActive={(activePanel.activePanel == 'Services') ?? true}
              >
                <a href="#services" className='anta-fonts'>Services</a>
              </Button>

              
             
              <Button variant="ghost" color="white"
                _hover={{ color: 'purple.200'}}
                _active={{ color: 'purple.200' }} // Define active color
                isActive={(activePanel.activePanel == 'ContactMe') ?? true}
              >
                <a href="#contactMe" className='anta-fonts'>Contact Me</a>
              </Button>
            </Box>
          </Box>
        </Flex>
      )}
    </>
  );
};

export default Navbar;
