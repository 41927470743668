import React, { useState, useEffect, useRef } from 'react';
import { Text, Box, Flex, Image, Heading, SimpleGrid, Grid } from "@chakra-ui/react";
import CollabrationAnimation from '../assets/images/animations/collabration.png';
import useAnimatedElement from '../assets/js/useAnimatedElement';

const Collabration = () => {

    let isVisibleContent = useAnimatedElement('collabration');
    let isVisibleImage = useAnimatedElement('collabrationImage');

    return (
      <Grid>
      <Flex mt={40} justifyContent="space-between" alignItems="center">
      <SimpleGrid columns={{ base: 1, md:2 }} alignItems="center" justifyContent="center" >
       <Box color="white"  id="collabration"  className={`slide-left-animation ${isVisibleContent ? 'visible-left' : ''}`} textAlign={{base:"center",md:"left"}}>

        <Heading fontSize={{ base:"2rem", md:"3rem"}} color="purple.100" className='caveat-fonts'>Collaboration and Impact:</Heading>
        <Text mt={4} className='paragraph'>
        While excelling independently, I truly shine in collaborative environments. I believe in the power of teamwork and the synergy that arises when diverse minds tackle complex problems together. By fostering open communication and mutual respect, I create environments where innovation thrives. I'm drawn to collaborative settings because they offer a chance to learn from others. Each team member brings unique methods, fostering a dynamic space for mutual growth and successful project outcomes.
        </Text>
       </Box>

       <Box id="collabrationImage" className={`slide-right-animation  ${isVisibleImage ? 'visible-right' : ''}`}>
        <Image src={CollabrationAnimation} w={{ base:"100%", md:"60%"}} float="right" className={`${isVisibleImage ? 'image-floating' : ''}`}/>
       </Box>
      </SimpleGrid>
       </Flex>
       </Grid>
    );
}

export default Collabration;
