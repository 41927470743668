import React, { useState } from 'react';
import { Card, CardBody, Heading, Stack, Image, Center, Grid, SimpleGrid } from '@chakra-ui/react';
import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverArrow,
    PopoverCloseButton,
} from '@chakra-ui/react';
import SERVICES from './data/services';

const Services = () => {
    const [openPopoverId, setOpenPopoverId] = useState(null);

    const handlePopoverOpen = (id) => {
        setOpenPopoverId(id);
    };

    const handlePopoverClose = () => {
        setOpenPopoverId(null);
    };

    return (
        <Center >
            <Grid mt='15%' textAlign="center">
                <Center>
                    <Heading  fontSize={{ base:"2rem", md:"3rem"}} color="purple.100" mb="2%" className='caveat-fonts heading'>Services</Heading>
                </Center>
                <Center>
                    <SimpleGrid columns={{ base: 1, sm: 2, md: 3, lg: 4, xl: 5 }} spacing="5" width="100%" alignItems="center" justifyContent="center" >
                        {SERVICES.map((SERVICE) => (
                            <Popover key={SERVICE.id} isOpen={openPopoverId === SERVICE.id} onOpen={() => handlePopoverOpen(SERVICE.id)} onClose={handlePopoverClose} closeOnBlur={false}>
                                <PopoverTrigger>
                                    <Card
                                        bg="#3d3d3d33"
                                        color="white"
                                        border="2px"
                                        borderColor="purple.200"
                                        p="2"
                                        width="100%"
                                        maxW="300px"
                                        height="100%"
                                        maxH="300px"
                                        margin="2%"
                                        cursor="pointer"
                                        className='hover-effect service-card'
                                    >
                                        <CardBody className='service-content'>
                                            <Center>
                                                <Image
                                                    src={SERVICE.img}
                                                    alt='Green double couch with wooden legs'
                                                    borderRadius='sm'
                                                    width="30%"
                                                    minW="80px"
                                                />
                                            </Center>
                                            <Center>
                                                <Stack mt="2" maxWidth="200px">
                                                    <Heading fontSize="100%" className='anta-fonts'>{SERVICE.title}</Heading>
                                                </Stack>
                                            </Center>
                                        </CardBody>
                                    </Card>
                                </PopoverTrigger>
                                <PopoverContent
                                    bg="purple.800"
                                    borderColor="purple.100"
                                    focusBorderColor="purple.100"
                                    _focusVisible={{ borderColor: "purple.100", boxShadow: "0 0 0 3px purple.100" }}
                                    key={SERVICE.id}
                                    display={openPopoverId === SERVICE.id ? 'block' : 'none'}
                                >
                                    <PopoverArrow bg="purple.800" />
                                    <PopoverCloseButton color="purple.100" onClick={handlePopoverClose} />
                                    <PopoverHeader fontWeight='semibold' borderColor="purple.100" color="purple.100">{SERVICE.title}</PopoverHeader>
                                    <PopoverBody color="purple.100">{SERVICE.description}</PopoverBody>
                                </PopoverContent>
                            </Popover>
                        ))}
                    </SimpleGrid>
                </Center>
            </Grid>
        </Center>
    );
};

export default Services;
