import React , { Suspense, useState, useEffect, useRef  } from 'react';
import { Heading, Center, Grid, SimpleGrid, Card, CardHeader, Text, Flex, CardBody, Box, Stack, Image, CardFooter, Button } from '@chakra-ui/react';
import BLOGS from './data/blogs';
import { useNavigate } from 'react-router-dom';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// import arrow icon
import { CgArrowLongRightC } from "react-icons/cg";

import '../src/assets/css/blogs.css';


const Blogs = () => {
    const navigate = useNavigate();

    const handleURL = (hash) => {
        // Check if there's a hash value
        if (hash) {
         // Remove the '#' symbol from the hash
         const targetId = hash.substring(1);
         // Find the target element by ID
         const targetElement = document.getElementById(targetId);
         // Check if the target element exists
         if (targetElement) {
         // Scroll to the target element without animation
         targetElement.scrollIntoView({ behavior: 'auto', block: 'start' });
         }
     }
       }
       
       useEffect(() => {
         // Get the hash value from the URL
         const hash = window.location.hash;
         handleURL(hash);
     }, []);

    return (
        <Center>
            <Grid mt='10%' textAlign="center">
                <Center className='heading-align'>
                    <Heading fontSize={{ base: "2rem", md: "3rem" }} color="purple.100" mb="2%" className='caveat-fonts heading ml-xl-45'>Blogs</Heading>
                    <Text mt={4} color="purple.500" fontSize={18}>
                        <Button
                                  mt={5}  
                                  size='md'
                                  height='48px'
                                  width='200px'
                                  border='2px'
                                  borderColor='purple.500'
                                  bg="#3d3d3d33"
                                  color="white"
                                  onClick={() => navigate(`/view-all-blogs`)}
                                  className='blogsButton '
                                >
                                 View All
                                </Button>
                             
                    </Text>
                </Center>
                <SimpleGrid columns={{ base: 1, md: 1, xl : 2}} spacing={{base : 10, md : 20, xl : 10}} alignItems="center" justifyContent="center" mt={8}>
                {BLOGS.map((BLOG, index) => (
                <Card data-type='Card'
                direction={{base:'column',sm:'row'}}
                overflow='hidden'
                key={BLOG.id}
                bg="#3d3d3d3d"
                className="blogs hover-effect"
                id="mainContainer"
              >
                <Image data-type='Image'
                  objectFit='cover'
                  maxW={{base:'100%',sm:'200px'}}
                  src={BLOG.img}
                  alt='Caffe Latte'
                />
              
                <Stack data-type='Stack'>
                  <CardBody data-type='CardBody'
                  >
                    <Heading data-type='Heading' size='md' id="header">{BLOG.title}</Heading>
              
                    <Text data-type='Text' py='2' dangerouslySetInnerHTML={{
                                                __html: BLOG.description.substring(0, 80) + ((BLOG.title + BLOG.description).length > 80
                                                    ? '...'
                                                    : '')
                                            }}
                                            className="blog-description"></Text>
                  </CardBody>
              
                  <CardFooter data-type='CardFooter'>
                  <Button
                        size='md'
                        height='48px'
                        width='200px'
                        border='2px'
                        borderColor='purple.500'
                        bg="#3d3d3d33"
                        color="white"
                        onClick={() => navigate(`/blog-detail`, { state: { blogId: BLOG.id } })}
                        className='blogsButton'
                    >
                        Read More
                    </Button>
                  </CardFooter>
                </Stack>
              </Card>
                ))}
                </SimpleGrid>
            </Grid>
        </Center>
    );

};

export default Blogs;