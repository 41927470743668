import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Box } from '@chakra-ui/react';

const MyNameAnimation = ({ onAnimationComplete }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 2000); // Duration of the first animation, adjust as needed

    const timer2 = setTimeout(() => {
      onAnimationComplete();
    }, 4000); // Duration of the second animation, adjust as needed

    return () => {
      clearTimeout(timer);
      clearTimeout(timer2);
    };
  }, [onAnimationComplete]);

  return (
    <>
      {isVisible && (
        <Box
          as={motion.div}
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 100 }}
          transition={{ duration: 2 }}
          textAlign="center"
          fontSize="3xl"
          fontWeight="bold"
          mt={20}
          fontFamily="Anta"
          color="white"
        >
          Jeet Kapdi
        </Box>
      )}
      {!isVisible && (
        <Box
          as={motion.div}
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 100 }}
          transition={{ duration: 2 }}
          textAlign="center"
          fontSize="3xl"
          fontWeight="bold"
          mt={20}
          fontFamily="Anta"
          color="white"
        >
          Let's know more about me!
        </Box>
      )}
    </>
  );
};

export default MyNameAnimation;
