import React, { useState } from 'react';
import { Card, CardBody, Button, Stack, Image, Center, Grid, SimpleGrid, Heading, FormControl, Text } from '@chakra-ui/react';
import { LiaFileDownloadSolid } from "react-icons/lia";
import './assets/css/contactme.css';
import ContactMeImg from './assets/images/animations/contactMe.png';
import Resume from './assets/files/Resume-Jeet-kapdi -Sr-Software-Engineer.pdf';
const ContactMe = () => {
    return (
        <Center>
            <Grid mt='15%' textAlign="center" w="100%">

                <Card
                    bg="#3d3d3d33"
                    color="white"
                    p="10"
                >
                    <CardBody className='service-content'>
                        <SimpleGrid columns={{base : 1, md : 2}}>
                        <Center w="100%">
                            <Stack mt="2" w="100%">
                            <Heading fontSize={{ base:"2rem", md:"3rem"}} color="purple.100" className='caveat-fonts'>Let's Start Work Togeather!</Heading>
                                <FormControl >
                                    <div class="form__group field">
                                    <input type="text" class="form__field" placeholder="Name" name="name" id='name'  required autoComplete='off'/>
                                    <label for="name" class="form__label">Name</label>
                                    </div>

                                    <div class="form__group field">
                                    <input type="email" class="form__field" placeholder="Email" name="email" id='email'  required autoComplete='off'/>
                                    <label for="email" class="form__label">Email</label>
                                    </div>

                                    <div class="form__group field">
                                    <textarea  class="form__field" placeholder="Message" name="message" id='message'  required autoComplete='off' rows="4"></textarea>
                                    <label for="message" class="form__label">Message</label>
                                    </div>

                                    <Stack >
                                    <Button
                                    mt={5}  
                                    size='md'
                                    height='48px'
                                    width='200px'
                                    border='2px'
                                    borderColor='purple.500'
                                    bg="#3d3d3d33"
                                    color="white"
                                    className='blogsButton'
                                    >
                                    Submit
                                    </Button>
                                    </Stack>

                                </FormControl>
                            </Stack>
                        </Center>
                        <Center>
                            <Image
                                src={ContactMeImg}
                                alt='Jeet Kapdi | Hire Best Developer Now!'
                                borderRadius='sm'
                                width="100%"
                                className='image-floating'
                            />
                        </Center>
                      
                    </SimpleGrid>

                    <Card
                    className='resume-box'
                    >
                         <SimpleGrid columns={{base : 1, md : 2}}>
                            <Center >
                                <Text 
                                className='caveat-fonts resume-qoute'
                                >Boosting efficiency is my passion. My resume showcases the results.</Text>
                            </Center>
                            <Center>
                                <a href={Resume} className='download-resume-link' download>
                                    <Text className='anta download-resume-button'>Download My Resume </Text>
                                    <LiaFileDownloadSolid className='download-resume-icon'/>
                                </a>
                            </Center> 
                         </SimpleGrid>
                    </Card>
                    
                </CardBody>
            </Card>

        </Grid>
                </Center >
        
    );
};

export default ContactMe;
