// PortfolioDetail.js
import React, { useState, useEffect } from 'react';
import { Heading, Center, Grid, Text, Box, Flex, Button } from '@chakra-ui/react';
import PROJECTS from './data/projects';
import { useLocation } from 'react-router-dom';
import theme from './custom-components/Theme';

// import `ChakraProvider` component
import { ChakraProvider, extendTheme  } from '@chakra-ui/react';

import { TiArrowBack  } from "react-icons/ti";

import { useNavigate } from 'react-router-dom';


const PortfolioDetail = () => {

  const navigate = useNavigate();
 
  const { state } = useLocation();
  const projectId = state?.projectId;
  
  const condition = (obj) => obj.id == projectId;
  const Project = PROJECTS.find(condition);

  const handlePress = () => {
    navigate('/#portfolio', { state: { userId: 789 } });
  }

  useEffect(() => {
    const top = document.getElementById('topPortfolioDetail');
    top.scrollIntoView({ behavior: 'auto', block: 'start' });
  },[]);
  
  if (!Project) {
    // Handle the case where the project with the given id is not found
    return (
      <ChakraProvider theme={theme} >
        <Center>
        <Flex mt="30%" alignItems="center">
        <Box color="white" >
          <Heading fontSize={{ base:"2rem", md:"3rem"}} color="purple.100" className='caveat-fonts'>Project Not Found!</Heading>
        </Box>
        </Flex>
        </Center>
      </ChakraProvider>
    );
  }
 
  return (
    <div className='container' id="topPortfolioDetail">
    <ChakraProvider theme={theme}>
      <Center>
    <Grid mt={10} alignItems="center">
      
       <Box  >
        <Flex >
          <Button 
           mt={5}  
           size='md'
           color="purple.200"
           onClick={handlePress}
           className='back-button'
           >
            <TiArrowBack size="xl"/>
           </Button>
        <Center>
        <a href={Project.link} target="_blank" rel={Project.description}> <Heading fontSize={{ base:"2rem", md:"4rem"}} color="purple.100" className='caveat-fonts'>{Project.title}</Heading></a>
        </Center>
        </Flex>
        </Box>

        <Box mt={10}>
          <Center>
          <a href={Project.link} target="_blank" rel={Project.description}> 
          <img src={Project.img} width="100%" className='hover-effect'/>
          </a>
          </Center>
        </Box>
      
        <Box color="white" mt={10} pb={10}>
         <Center>
           {/* <Text mt={4} className='paragraph'>
              {Project.description}
            </Text> */}
             <Text mt={4} className='paragraph' dangerouslySetInnerHTML={{ __html: Project.description }}></Text>
          </Center>
        </Box>

       </Grid>
       
       </Center>
    </ChakraProvider>
    
    </div>
  );
};

export default PortfolioDetail;
