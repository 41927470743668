import WebDevelopmentImg from '../assets/images/services/website.png';
import BackendDevelopmentImg from '../assets/images/services/backenddevelopment.png';
import DatabaseImg from '../assets/images/services/database.png';
import DevopsImg from '../assets/images/services/devops.png';
import QualityImg from '../assets/images/services/quality-control.png';
import MaintainanceImg from '../assets/images/services/maintenance.png';
import ConsultingImg from '../assets/images/services/consultation.png';
import UIUXImg from '../assets/images/services/uiux.png';
import ProjectManagementImg from '../assets/images/services/project-management.png';
import SEOImg from '../assets/images/services/seo.png';

const SERVICES = [
    {
        id:1,
        title:"Web Development",
        description : "Developing web applications, websites, and web services using frontend technologies like HTML, CSS, JavaScript, and frontend frameworks like React.",
        img : WebDevelopmentImg
    },
    {
        id:2,
        title:"Backend Development",
        description : "Building server-side logic, databases, and APIs using programming languages such as JavaScript (Node.js), PHP, along with frameworks like Codeigniter or Laravel.",
        img : BackendDevelopmentImg
    },
    {
        id:3,
        title:"Database Design and Management",
        description : "Designing, implementing, and managing databases using relational databases like MySQL, MSSQL databases like Firebase.",
        img : DatabaseImg
    },
    {
        id:4,
        title:"DevOps and Deployment",
        description : "Setting up continuous integration/continuous deployment (CI/CD) pipelines, managing cloud infrastructure (AWS, Google Cloud Platform, Microsoft Azure), and ensuring the scalability, reliability, and security of applications.",
        img : DevopsImg
    },
    {
        id:5,
        title:"Maintenance and Support",
        description : "Providing ongoing maintenance, updates, and technical support for existing software applications, including bug fixes, performance optimizations, and feature enhancements.",
        img : MaintainanceImg
    },
    {
        id:6,
        title:"Quality Assurance and Testing",
        description : "Writing and executing test cases, performing manual and automated testing, and ensuring the quality and reliability of software products.",
        img : QualityImg
    },
    {
        id:7,
        title:"Consulting and Technical Advisory",
        description : "Offering technical expertise, guidance, and recommendations on software architecture, design patterns, best practices, and technology stack selection.",
        img : ConsultingImg
    },
    {
        id:8,
        title:"UI/UX Design",
        description : "Collaborating with designers to create intuitive and visually appealing user interfaces (UI) and user experiences (UX) for web and mobile applications.",
        img : UIUXImg
    },
    {
        id:9,
        title:"Project Management",
        description : "Leading or participating in project management activities, including requirement analysis, project planning, task estimation, and team coordination.",
        img : ProjectManagementImg
    },
    {
        id:10,
        title:"SEO Optimization",
        description : " Implementing on-page and off-page SEO techniques to improve website visibility and search engine rankings. This may involve keyword research, optimizing meta tags, creating SEO-friendly URLs, improving site speed and performance, optimizing images, implementing structured data markup, etc.",
        img : SEOImg
    }
];

export default SERVICES;