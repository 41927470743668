import React, { useState, useEffect, useRef } from 'react';
import { Card, Center } from '@chakra-ui/react';
import { keyframes } from '@emotion/react';
import MessageCard from '../custom-components/MessageCard';
import myProfilePic from '../assets/images/myProfile.jpg'; 

// Define a keyframe animation using Emotion
const slideInAnimation = keyframes`
    from {
        opacity: 0;
        transform: translateY(200px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

const Welcome = () => {
    const [messages, setMessages] = useState([]);
    const messagesEndRef = useRef(null);

    useEffect(() => {
        const messageList = [
            "Hello and welcome!",
            "I'm JEET KAPDI!",
            "A Senior Software Engineer with a deep passion for crafting innovative solutions and pushing the boundaries of technology",
            "With 4.6 years of hands-on experience in web development.",
            "I bring a blend of expertise, creativity, and dedication to every project I undertake."
        ];

        const delayBetweenMessages = 2000;

        const addMessages = async () => {
            for (const message of messageList) {
                await new Promise(resolve => setTimeout(resolve, delayBetweenMessages));
                setMessages(prevMessages => [...prevMessages, message]);
            }
        };

        addMessages();
    }, []);

    return (
        <Center bg="#ffffff00" mt="2" color="white" display="content"  alignItems="start" >
            <Card
                bg="#ffffff00" // Darker background color
                boxShadow="lg" // Add some shadow for better visibility
                
            >
                {messages.map((message, index) => (
                    <MessageCard
                        key={index}
                        message={message}
                        sender={{ avatar: myProfilePic, name: "Jeet Kapdi" }}
                       
                    />
                ))}
                <div ref={messagesEndRef} />
            </Card>
        </Center>
    );
}

export default Welcome;
