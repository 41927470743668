import React, { useState, useEffect, useRef } from 'react';
import { Text, Box, Flex, Image, Heading, SimpleGrid, Grid  } from "@chakra-ui/react";
import DrivesmeAnimation from '../assets/images/animations/drivesme.png';
import useAnimatedElement from '../assets/js/useAnimatedElement';

const Drivesme = () => {

  let isVisibleContent = useAnimatedElement('drivesMe');
  let isVisibleImage = useAnimatedElement('drivesMeImage');

    return (
      <Grid>
      <Flex mt={40} justifyContent="space-between" alignItems="center">
      <SimpleGrid columns={{ base: 1, md:2 }} alignItems="center" justifyContent="center" >
        <Box id="drivesMeImage"  className={`slide-left-animation ${isVisibleImage ? 'visible-left' : ''}`}>
        <Image src={DrivesmeAnimation} w={{ base:"100%", md:"60%"}} float="left" className={`${isVisibleImage ? 'image-floating' : ''}`}/>
       </Box>
       <Box color="white" float="right" id="drivesMe" className={`slide-right-animation  ${isVisibleContent ? 'visible-right' : ''}`} textAlign={{base:"center",md:"right"}}>
        <Heading fontSize={{ base:"2rem", md:"3rem"}} color="purple.100" className='caveat-fonts'> What Drives Me:</Heading>
        <Text mt={4} className='paragraph'>
        What truly sets my soul on fire is the process of creation—the ability to transform ideas into tangible, functional solutions. I thrive on challenges and am always eager to learn and adapt to new technologies and methodologies. My dedication to my craft is unwavering, and I approach each project with a relentless pursuit of excellence. Motivated by my intrinsic desire for success, I maintain discipline in my work, setting personal goals and striving towards them. This self-imposed discipline not only fuels my commitment to my craft but also propels me toward the vision of becoming successful one day.
        </Text>
       </Box>
       </SimpleGrid>
       </Flex>
       </Grid>
    );
}

export default Drivesme;
