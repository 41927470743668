import React, { useState, useEffect } from 'react';

function Clock(type) {
  const [time, setTime] = useState(new Date());
  const [loadTime, setLoadTime] = useState('');

  // Update the time once when the component is mounted
  useEffect(() => {
    const currentTime = new Date();
    setTime(currentTime);
    setLoadTime(currentTime.toLocaleTimeString());

    // Update the time at intervals
    const timerID = setInterval(() => {
      tick();
    }, 1000);

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(timerID);
    };
  }, []);

  // Update the time state
  function tick() {
    setTime(new Date());
  }

    if(type="loadTime"){
        return (
            <div>
            <h2>{loadTime}</h2>
            </div>
        );
    } else {
        return (
            <div>
            <h2>{time.toLocaleDateString()} {time.toLocaleTimeString()}</h2>
            </div>
        );
    }
  
}

export default Clock;
