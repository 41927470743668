import React from 'react';
import Navbar from './Navbar';
import Aboutme from './Aboutme';
import Services from './Services';
import Portfolio from './Portfolio';
import Blogs from './Blogs';
import ContactMe from './ContactMe';
import useNavbarActive from './assets/js/useNavbarActive';
import Connectivity from './Connectivity';
// Css
import './assets/css/index.css';
import { useEffect } from 'react';

const LandingPage = () => {
  let isVisibleAboutMe = useNavbarActive('aboutme', 'aboutMeEnd');
  let isVisibleServices = useNavbarActive('services', 'servicesEnd');
  let isVisiblePortfolio = useNavbarActive('portfolio', 'portfolioEnd');
  let isVisibleBlog = useNavbarActive('blogs', 'blogsEnd');
  let isVisibleContactMe = useNavbarActive('contactMe', 'contactMe');

  let activePanel = '';
  if(isVisiblePortfolio) {
    activePanel = 'Portfolio';
  }
  if(isVisibleServices) {
    activePanel = 'Services';
  }
  if(isVisibleAboutMe) {
    activePanel = 'AboutMe';
  }
  if(isVisibleBlog) {
    activePanel = 'Blogs';
  }
  if(isVisibleContactMe) {
    activePanel = 'ContactMe';
  }

  useEffect(()=> {
    document.getElementById('root').style.setProperty('overflow-x', 'hidden');
  },[]);

  return (
    <>
    <div className="container overflow-x-hidden">
      <Navbar activePanel={activePanel} />
      <div className='section-height' id="aboutme" >
        <Aboutme />
        <div id="aboutMeEnd"></div>
      </div>

      <div className='section-height' id="portfolio">
        <Portfolio />
        <div id="portfolioEnd"></div>
      </div>

      <div className='section-height' id="blogs">
        <Blogs />
        <div id="blogsEnd"></div>
      </div>
      
      <div className='section-height' id="services">
        <Services />
        <div id="servicesEnd"></div>
      </div>

      <div className='section-height' id="contactMe">
        <ContactMe />
        <div id="contactMeEnd"></div>
      </div>

    </div>
    <div className='connectivity' id="connectivity">
        <Connectivity />
    <div id="connectivityEnd"></div>
  </div>
  </>
  );
};

export default LandingPage;
