import { useEffect, useState } from 'react';

const useNavbarActive = (elementId, secondElementId) => {
  let [isActive, setIsActive] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      let element = document.getElementById(elementId);
      let secondElement = document.getElementById(secondElementId);

      let elementRect;
      let secondRect;

      if (element) {
        elementRect = element.getBoundingClientRect();
      }

      if (secondElement) {
        secondRect = secondElement.getBoundingClientRect();
      }

      let isInView = 
        (elementRect && ((elementRect.top -40) <= window.innerHeight && (elementRect.bottom -40) >= 0)) ||
        (secondRect && ((secondRect.top - 40) <= window.innerHeight && (secondRect.bottom -40) >= 0));

      setIsActive(isInView);
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [elementId, secondElementId]);

  return isActive;
};

export default useNavbarActive;
