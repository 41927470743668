const Footer = () => {

    return (
        <div class="ocean ">
        <div class="wave"></div>
        <div class="wave wave2"></div>
        </div>
    );

}

export default Footer;  